import { createContext } from 'react'

export const ItineraryDataContext = createContext([{}, () => {}])
export const PriceContext = createContext([{}, () => {}])
export const ItineraryUpdateContext = createContext([{}, () => {}])
export const CouponDataContext = createContext([{}, () => {}])
export const UserProfileContext = createContext([{}, () => {}])
export const TravellerDetailsContext = createContext([{}, () => {}])
export const SplRtContext = createContext([{}, () => {}])
export const AddonsDataContext = createContext([{}, () => {}])
export const AddonsUpdateContext = createContext([{}, () => {}])
export const SuperCoinsContext = createContext([{}, () => {}])
export const FareFamilyContext = createContext([{}, () => {}])
export const CtUpgradeContext = createContext([{}, () => {}])
export const MediCancelContext = createContext([{}, () => {}])
