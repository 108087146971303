import React, { useState, useEffect } from 'react'
import CsrRouter from 'router'
import { StatsigProvider, Statsig } from "statsig-react";
import { v4 as uuidv4 } from 'uuid';
// import lm from 'locale' // locale or language map
import {
    CurrencyContext,
    UserContext,
    UserProfileContext,
    ProfileDataContext,
    CtUpgradeContext,
    OfferContext
} from 'context/flights/results'
import { userLoggedInObj } from 'utils/flights/results'
import { logConsole, groupCollapsedConsole } from 'utils/logError'
import { getScript, GACode, getCookie, delete_cookie,isUserSignedIn, loadRavenSDK } from 'utils/browserHelper'
import { DOMAIN_COUNTRY_MAP } from 'constants/flightResults'

import { DOMAIN } from 'constants/base'
import {loginPush, responseMapper, sendProfileData} from 'analytics/clevartapProfile'
import { deviceId } from 'utils/deviceIdUtils'
import { profilePush } from 'utils/uiHelper'
import ApmProvider from 'elasticApm/apmProvider'
import { isStatsigIDInLocalStorageOrCookies } from 'utils/statsig.utils';

function App() {
    const currencyHook = useState([])
    const userDataHook = useState(() => userLoggedInObj())
    const userProfileHook = useState({})
    const profileHook = useState({})
    const ctUpgradeHook = useState({})
    const ctEnv = process.env.CT_ENV === 'production' ? 'prod' : 'staging'
    // once the raven URLs are stored in config and this variable ravenSDKUrl will be removed from here
    const ravenSDKUrl = process.env.CT_ENV !== 'production' 
        ? "https://fastui.cltpstatic.com/raw/upload/resources-qa/raven-web-sdk/static/js/bundle.b37a3ba1.js" : ''
    const [offerHook, setOfferHook] = useState({})
    const offerJSON = async () => {
        await getScript(
            `https://www.cleartrip.com/offermgmt/offerJson/${ctEnv}/offerSlot.js?` + new Date().getTime(),
            () => {
                setOfferHook(Math.random()) // Hack for BBD
            }
        )
    }
    useEffect(() => {
    
        if(getCookie('ct-pwa')){
            delete_cookie('ct-pwa')
          }
            if (DOMAIN_COUNTRY_MAP[DOMAIN] === 'IN') {
                offerJSON()
                    GACode()
                    if(typeof window.ravenWebManager === 'object'){
                        console.log("Raven loaded")
                    }
                    else{
                        console.log("Failed to load Raven SDK")
                    } 
                // loadRavenSDK(ravenSDKUrl)
                //     .then(()=>{console.log("Raven loaded")})
                //     .catch((e)=>{console.log("Failed to load Raven SDK")})
        }
            window.Statsig = Statsig

            if (isUserSignedIn() && !loginPush) {
                profilePush()
            }
            deviceId()
    },[])
    groupCollapsedConsole('Runtime Config')
    logConsole('NODE_ENV - ', process.env.NODE_ENV)
    logConsole('CT_ENV   - ', process.env.CT_ENV)
    logConsole('LANG     - ', process.env.LOCALE_LANG)
    logConsole('TEMPLATE - ', process.env.TEMPLATE)
    logConsole('BITBUCKET_BRANCH - ', process.env.BITBUCKET_BRANCH)
    logConsole('BITBUCKET_BUILD_NUMBER - ', process.env.BITBUCKET_BUILD_NUMBER)
    logConsole('BITBUCKET_COMMIT - ', process.env.BITBUCKET_COMMIT)
    logConsole('CONTENT_DIR - ', process.env.CONTENT_DIR)
    logConsole('CT_PUBLIC_USER_API_BASE_URL - ', process.env.CT_PUBLIC_USER_API_BASE_URL)
    groupCollapsedConsole('', true)
    return (
        <div className="App flex flex-column" style={{ minHeight: '100vh', width: '100%' }}>
            <ApmProvider>
                <StatsigProvider
                    sdkKey={process.env.DESKTOP_PUBLIC_STATSIG_CLIENT_KEY }
                    user={{
                        customIDs: {
                            stableID: isStatsigIDInLocalStorageOrCookies(uuidv4())
                        }
                    }}
                    options={{
                        overrideStableID: isStatsigIDInLocalStorageOrCookies(uuidv4())
                    }}
                    waitForInitialization={false}
                >
                    <ProfileDataContext.Provider value={profileHook}>
                        <UserContext.Provider value={userDataHook}>
                            <OfferContext.Provider value={offerHook}>
                                <UserProfileContext.Provider value={userProfileHook}>
                                    <CurrencyContext.Provider value={currencyHook}>
                                        <CtUpgradeContext.Provider value={ctUpgradeHook}>
                                            <CsrRouter />
                                        </CtUpgradeContext.Provider>
                                    </CurrencyContext.Provider>
                                </UserProfileContext.Provider>
                            </OfferContext.Provider>
                        </UserContext.Provider>
                    </ProfileDataContext.Provider>
                </StatsigProvider>
            </ApmProvider>
        </div>
    )
}

export default App
