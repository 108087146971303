import  React ,{ useEffect } from 'react';

import { init as initApm } from '@elastic/apm-rum';

import { apmConfig } from './apmConfig';


const ApmProvider = ({ children }) => {
    useEffect(() => {
        const apm = initApm(apmConfig);
        window.apm = apm;
    }, []);

    return children ? <>{children}</> : <></>;
};

export default ApmProvider;