import React, { useRef, useContext } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Field } from '@cleartrip/bento'
import { AmendmentResultsContext } from 'context/flights/results'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg'
import { ReactComponent as CrossIcon } from '../../assets/icons/crossIcon.svg'

const DropdownSelect = ({
    children,
    className,
    label,
    active,
    placeHolder,
    variant,
    onClick,
    width,
    border,
    onChange,
    hasError,
    arrow,
    isSearchDropdown,
    height,
    hideArrow,
    tripSelectOnCross,
    showCross,
    disabled = false,

    ...style
}) => {
    const [amendmentContext, setAmendmentContext] = useContext(AmendmentResultsContext)

    const buttonRef = useRef(null)
    const rotatedStyle = {
        transform: `rotate(-180deg)`,
        color: '#999'
    }
    const isRTL = process.env.CONTENT_DIR === 'RTL'
    const selectClassName = classNames(
        'flex flex-middle flex-between t-all fs-2 focus:bc-secondary-500 bg-transparent',
        {
            'bc-secondary-500': active,
            'bc-neutral-100': !active && !hasError,
            'c-pointer': variant === 'select',
            'pr-2 pl-3 pt-2 pb-2 ba br-4 h-8': border,
            // 'pr-2 pl-3 pt-3 pb-3 ba br-4 h-8': !withoutBorder,
            'bc-error-500': hasError,
            'pe-none': disabled,
            'bg-neutral-100': disabled
        },
        className
    )
    const errorClass = classNames(
        'flex flex-middle flex-between t-all fs-2',
        {
            'pr-2 pl-3 pt-2 pb-2 ba br-4 h-8': border
        },
        className
    )
    let Arrow
    if (arrow) {
        if (active) Arrow = <ArrowIcon className={classNames('t-all c-secondary-500', isRTL ? 'mr-2' : 'ml-2')} />
        else Arrow = <ArrowIcon className={classNames('t-all', isRTL ? 'mr-2' : 'ml-2')} style={rotatedStyle} />
    }

    function onSearchChange(e) {
        onChange(e)
        onClick('open')
    }
    function onFieldClick(e) {
        e.target.select()
        onClick(isSearchDropdown ? 'open' : 'close')
    }

    return (
        <>
            {children}
            {variant === 'field' && !children && (
                <Field
                    size="sm"
                    className={hasError ? errorClass : selectClassName}
                    placeholder={placeHolder}
                    value={label}
                    onChange={e => onSearchChange(e)}
                    onClick={event => onFieldClick(event)}
                    hasError={hasError}
                    {...style}
                />
            )}
            {variant === 'select' && !children && (
                <button
                    onClick={onClick}
                    className={selectClassName}
                    style={{
                        minWidth: `${width}px`,
                        height: `${height}px`
                    }}
                    ref={buttonRef}
                >
                    {label ? (
                        <div className="fs-2 c-inherit flex flex-nowrap">{label}</div>
                    ) : (
                        <div className="fs-inherit c-neutral-400 flex-nowrap">{placeHolder}</div>
                    )}
                    {!hideArrow && Arrow}
                    {hideArrow && showCross && !amendmentContext.isAmendment && (
                        <CrossIcon
                            onClick={e => {
                                e.stopPropagation()
                                buttonRef.current.blur()
                                if (typeof tripSelectOnCross === 'function') {
                                    tripSelectOnCross('One way', 'tripType', true)
                                }
                            }}
                        />
                    )}
                </button>
            )}
        </>
    )
}

DropdownSelect.defaultProps = {
    variant: 'select',
    width: 104,
    active: false,
    isSearchDropdown: true,
    border: true,
    arrow: true,
    className: ''
}

DropdownSelect.propType = {
    variant: PropTypes.string,
    width: PropTypes.number,
    active: PropTypes.bool,
    border: PropTypes.bool,
    arrow: PropTypes.bool,
    className: PropTypes.string
}

export default DropdownSelect
