import React from 'react'
import { ReactComponent as UpgradeIcon } from 'assets/icons/upgradeIcon.svg'
import { ReactComponent as MyntraCircle } from 'assets/logos/myntracircle.svg'
import { ReactComponent as MyntraLogo } from 'assets/logos/myntra.svg'
import { ReactComponent as FlipkartLogo } from 'assets/logos/flipkartPlus.svg'
import { ReactComponent as FlipkartVip} from 'assets/logos/flipkartVip.svg'
import { ReactComponent as FlipkartPremium} from 'assets/logos/flipkartPremium.svg'
import { ReactComponent as AxisLogo } from 'assets/logos/axisText.svg'
import { ReactComponent as AxisLogoRound } from 'assets/logos/axisIconround.svg'
import { ReactComponent as AxisLogoLight } from 'assets/logos/AxisLogoLight.svg'
import { ReactComponent as FlipkartLogoVipLight } from 'assets/logos/FlipkartLogoVipLight.svg'
import { ReactComponent as FlipkartPremiumLight } from 'assets/logos/flipkartPremiumLight.svg'
import { ReactComponent as UpgradeIconLight } from 'assets/logos/UpgradeIconLight.svg'
import { ReactComponent as UpgradeTick } from 'assets/icons/upgradeTick.svg'
import { ReactComponent as FlipkartVipIcon } from 'assets/logos/flipkartVipIcon.svg'
import PopIcon from 'assets/logos/NewPOPLogo.png'



import { Spacer } from '@cleartrip/bento'
export const benifits = {
    CT_UPGRADE: {
        text: 'CT_UPGRADE',
        component: (props = {}) => (
            <>
                {props.hideSpacer ? <></> : <Spacer className="ml-1" />}
                <UpgradeIcon {...props} />
            </>
        )
    }
}
export const CT_UPGRADE_SEAT_CALLOUT = 'Choose your favourite seat with CT Upgrade'

export const benefitGroupMap = {
    FLIPKART_PLUS: 'Members',
    FLIPKART_PREMIUM: 'Members',
    MYNTRA_ELITE: 'Elite Insiders',
    MYNTRA_ICON: 'Icon Insiders',
    AXIS_LOYALTY: 'Users',
    POP_BENEFITS : 'Card Users'
}

export const benefitGroupPrefix = {
    MYNTRA_ELITE: 'Elite',
    MYNTRA_ICON: 'Icon',
}

export const benefitIconGroupMap = {
    FLIPKART_PLUS: 'flipkart',
    FLIPKART_VIP: 'FLIPKART_VIP',
    FLIPKART_PREMIUM : 'FLIPKART_PREMIUM',
    POP_BENEFITS : 'POP_BENEFITS',
    MYNTRA_ELITE: 'myntra',
    MYNTRA_ICON: 'myntra',
    AXIS_LOYALTY: 'axis',
}


export const MYNTRA_ELITE = 'MYNTRA_ELITE'
export const MYNTRA_ICON = 'MYNTRA_ICON'
export const MYNTRA = 'myntra'
export const FLIPKART = 'flipkart'
export const FLIPKART_PREMIUM = 'FLIPKART_PREMIUM'
export const FLIPKART_VIP = 'FLIPKART_VIP'
export const AXIS = 'axis'
export const AXIS_LOYALTY = 'AXIS_LOYALTY'
export const CT_UPGRADE = 'CT_UPGRADE'
export const POP_BENEFITS = 'POP_BENEFITS'
export const AXIS_MESSAGE = 'Free seat, meal and free cancellations or date changes with'

export const iconComponents = {
    [MYNTRA_ELITE]: ({width, height})=> <MyntraLogo width={width} height={height}/>,
    [MYNTRA_ICON]: ({width, height})=> <MyntraLogo width={width} height={height}/>,
    [MYNTRA]: ({width, height})=> <MyntraLogo width={width} height={height}/>,
    [AXIS_LOYALTY]:({width, height}) => <AxisLogo width={width} height={height}/>,
    [AXIS]:({width, height}) => <AxisLogo width={width} height={height}/>,
    [FLIPKART]:({width, height})=> <FlipkartLogo width={width} height={height}/>,
    [FLIPKART_VIP]:({width, height})=> <FlipkartVip width={width} height={height}/>,
    [FLIPKART_PREMIUM] : ({width, height}) => <FlipkartPremium width={width} height={height}/>,
    [CT_UPGRADE]:({width, height})=> <UpgradeIcon width={width} height={height}/>,
    [POP_BENEFITS]:({width, height})=> <img src={PopIcon} width={width} height={height}/>
    
};

export const iconComponentsLight = {
    [MYNTRA_ELITE]: ({width, height})=> <MyntraLogo width={width} height={height}/>,
    [MYNTRA_ICON]: ({width, height})=> <MyntraLogo width={width} height={height}/>,
    [MYNTRA]: ({width, height})=> <MyntraLogo width={width} height={height}/>,
    [AXIS_LOYALTY]:({width, height}) => <AxisLogoLight width={width} height={height}/>,
    [FLIPKART]:({width, height})=> <FlipkartLogo width={width} height={height}/>,
    [FLIPKART_VIP]:({width, height})=> <FlipkartLogoVipLight width={width} height={height}/>,
    [FLIPKART_PREMIUM]:({width, height})=> <FlipkartPremiumLight width={width} height={height}/>,
    [CT_UPGRADE]:({width, height})=> <UpgradeIconLight width={width} height={height}/>,
};

export const iconComponentsRound = {
    [MYNTRA_ICON]: ({width, height})=> <MyntraCircle width={width} height={height}/>,
    [MYNTRA_ELITE]: ({width, height})=> <MyntraLogo width={width} height={height}/>,
    [MYNTRA]: ({width, height})=> <MyntraLogo width={width} height={height}/>,
    [AXIS_LOYALTY]:({width, height}) => <AxisLogoRound width={width} height={height}/>,
    [FLIPKART]:({width, height})=> <FlipkartLogo width={width} height={height}/>,
    [FLIPKART_VIP]:({width, height})=> <FlipkartVipIcon width={width} height={height}/>,
    [FLIPKART_PREMIUM]:({width, height})=> <FlipkartPremium width={width} height={height}/>,
    [CT_UPGRADE]:({width, height})=> <UpgradeTick width={width} height={height}/>,
    [POP_BENEFITS]:({width, height})=> <img src={PopIcon} width={width} height={height}/>
};

export const benefitCardClass = {
    [AXIS]: 'axis-card',
    [AXIS_LOYALTY]: 'axis-card',
    [FLIPKART]: 'flipkart',
    [FLIPKART_VIP]: 'flipkart-card',
    [FLIPKART_PREMIUM]: 'flipkart-premium-card',
    [CT_UPGRADE]: 'ct-upgrade-card',
    [MYNTRA_ICON]: 'myntra-card',
    [MYNTRA]: 'myntra-card',
    [MYNTRA_ELITE]: 'myntra-card',
};

export const benefitProgramNames = {
    [FLIPKART]: 'Flipkart',
    [FLIPKART_VIP]: 'Flipkart VIP',
    [FLIPKART_PREMIUM]: 'Flipkart Premium',
    [AXIS_LOYALTY]: 'Axis bank',
    [AXIS]: 'Axis bank',
    [MYNTRA_ICON]: 'Myntra',
    [MYNTRA]: 'Myntra',
    [MYNTRA_ELITE]: 'Myntra',
    [AXIS]: 'Axis bank',
    [CT_UPGRADE]: 'CT Upgrade',
    [POP_BENEFITS]: 'POP Card'
};

export const programBgCode = {
    [FLIPKART]: '#ECF8F4',
    [FLIPKART_VIP]: '#ECF8F4',
    [FLIPKART_PREMIUM]: '#F0F5FF',
    [AXIS_LOYALTY]: '#FFF3F8',
    [AXIS]: '#FFF3F8',
    [MYNTRA_ICON]: '#ECF8F4',
    [MYNTRA]: '#ECF8F4',
    [MYNTRA_ELITE]: '#ECF8F4',
    [AXIS]: '#FFF3F8',
    [CT_UPGRADE]: '#FFF3F8',
    [POP_BENEFITS]: '#FFF3F8'
};