// Add URL parser etc
import querystring from 'querystring'
import { LSTORAGE_COUNT_TO_SAVE, LSTORAGE_FILTER_DATA_KEY } from 'constants/flightResults'
import { logConsole, groupCollapsedConsole } from 'utils/logError'
import langTranslate from 'locale'
import { formatCurrency, getConversionRate, getCurrencyByLocale } from 'utils/currencyHelper'
let timeoutId
export const resolveKeysInObjectsArrays = (p, o) => {
    const reducerFunction = (xs, x) => {
        return xs && xs[x] ? xs[x] : null
    }
    return p.reduce(reducerFunction, o)
}

export const isEmpty = obj => {
    if (obj instanceof Date) {
        return false
    }
    // null and undefined are "empty"
    if (obj == null || obj == 'undefined') {
        return true
    }

    const isNumber = value => Object.prototype.toString.call(value) === '[object Number]'
    const isNaN = value => isNumber(value) && value.toString() === 'NaN'

    if (isNumber(obj)) {
        return isNaN(obj)
    }

    /** Assume if it has a length property with a non-zero value
     * that that property is correct.
     */
    if (obj.length > 0) {
        return false
    }
    if (obj.length === 0) {
        return true
    }

    /** If it isn't an object at this point
     * it is empty, but it can't be anything *but* empty
     * Is it empty?  Depends on your application.
     */
    if (typeof obj !== 'object') {
        return true
    }

    /**  Otherwise, does it have any properties of its own?
     * Note that this doesn't handle
     * toString and valueOf enumeration bugs in IE < 9
     */
    const keys = Object.keys(obj)
    for (let i = 0, key = keys[i]; i < keys.length; i += 1) {
        if (hasOwnProperty.call(obj, key)) {
            return false
        }
    }
    return true
}

export const toQueryString = (object, sep = '&', eq = '=') => {
    return querystring.stringify(object, sep, eq)
}

export const fromQueryString = (queryString, sep = '&', eq = '=') => {
    return querystring.parse(queryString, sep, eq)
}
export const pluralize = (value, text, suffix = 's') =>
    +value === 1 ? `${value} ${langTranslate(text)}` : `${value} ${langTranslate(`${text}${suffix}`)}`

export const pluralizeText = (count, text, suffix = 's') => {
    if (count > 1) return text + suffix
    return text
}

export const labelPluralize = (value, text, suffix = 's') =>
    +value === 1 ? `${langTranslate(text)}` : `${langTranslate(`${text}${suffix}`)}`

export const replaceQueryInUrl = (browserHistory, searchQuery) => {
    if (searchQuery.intl && searchQuery.intl === 'y') {
        browserHistory.location.pathname = `${appPrefix}/flights/international/results`
    } else {
        browserHistory.location.pathname = `${appPrefix}/flights/results`
    }
    const currentUrl = browserHistory.location
    const stringify = toQueryString(searchQuery)
    browserHistory.location.search = decodeURIComponent(stringify)
    browserHistory.push(currentUrl)
}
export function debounce(func, delay) {
    return function() {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            func.apply(this, arguments)
        }, delay)
    }
}

export function throttle(callback, delay, options = {}) {
    let flag = true
    return () => {
        if (flag) {
            flag = false
            setTimeout(() => {
                callback(options)
                flag = true
            }, delay)
        }
    }
}

export function getCookie(name) {
    if (document.cookie) {
        var nameEQ = name + '='
        var ca = document.cookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) == ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
        }
        return ''
    } else {
        return ''
    }
}

export const setCookie = (name, value, days) => {
    let expires
    if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = `; expires=${date.toGMTString()}`
    } else {
        expires = ''
    }
    document.cookie = `${name}=${value}${expires}; path=/`
}

export const setPaymentCookie = (name, value, days) => {
    let expires
    if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = `; expires=${date.toGMTString()}`
    } else {
        expires = ''
    }
    document.cookie = `${name}=${value}${expires}; path=/pay/air`
}

export function deleteCookie(name) {
    setCookie(name, '', -1)
}

export function getCTAuthCookie() {
    return getCookie('usermisc')
}

export function getUserAuthValues() {
    const [email, profileName, gender, photo, userId] = decodeURIComponent(getCookie('userid') || '').split('|')
    return {
        email,
        profileName,
        gender,
        photo,
        userId
    }
}

export function getUserLoggedInValues(){
    let signedIn = false
    const usermisc = getCookie('usermisc') || ''
    const { userId = '' } = getUserAuthValues()
    if (usermisc?.includes('SIGNED_IN') && !isEmpty(userId) && !signedIn) {
        signedIn = true
    }
    return signedIn
}

export const numberWithCommas = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const jsonObjectToFormData = obj => {
    const keyArray = Object.keys(obj).map(key => {
        return `${key}=${obj[key]}`
    })
    return keyArray.join('&')
    // TODO: remove these lines at the end
    // var bodyFormData = new FormData()
    // for (let key in obj) {
    //     bodyFormData.set(key, obj[key])
    // }
    // return bodyFormData
}

export const compare = (arr, key, callback) =>
    arr.reduce((prev, curr) => (callback(prev[key], curr[key]) ? prev : curr), {})[key]

//addded for some time
export const currencyFormat = (value, locale = 'en-IN', currency = 'INR') => {
    if (value === 'null') {
        return 'N/A'
    }
    let formatted = parseInt(value, 10).toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0
    })
    formatted = formatted.substring(0, 1) + ' ' + formatted.substring(1, formatted.length)
    return formatted
}

export const currencyPrefix = currencyCode => {
    if (!currencyCode) return
    let currencySymbol = Number()
        .toLocaleString(undefined, { style: 'currency', currency: currencyCode })
        .split('0.00')[0]
    return currencySymbol
}
export const getURLSearchParam = param => {
    let searchParams = resolveKeysInObjectsArrays(['location', 'search'], window) //window.location.search
    if (!isEmpty(searchParams)) {
        searchParams = searchParams.replace('?', '').split('&')
        for (let i = 0; i < searchParams.length; i++) {
            if (searchParams[i].indexOf(param) > -1) {
                const paramVal = searchParams[i].split('=')[1]
                return paramVal
            }
        }
    }
    return
}

export const pageLoadTime = () => {
    if (window) {
        if (window.lastRenderTimeStamp > 0) {
            return new Date().getTime() - window.lastRenderTimeStamp
        } else {
            const time = new Date().getTime() - window.performance.timing.connectStart
            return time > -1 ? time : 0
        }
    } else {
        return 0
    }
}

// LSTORAGE_COUNT_TO_SAVE

export function getFiltersFromLocalStorage(key, emptyVal = '') {
    try {
        // const val = localStorage.getItem(key)
        let configsStr = localStorage.getItem(LSTORAGE_FILTER_DATA_KEY)
        if (!configsStr) return {}
        let filterConfigs = JSON.parse(configsStr)
        // let reqConfig = filterConfigs[key] || {}
        let reqConfig = {}
        if (Array.isArray(filterConfigs)) {
            for (let config of filterConfigs) {
                if (config[key]) reqConfig = config[key]
            }
        }
        // console.log('localStorage - ',reqConfig)
        return reqConfig
        // if (val) return val
    } catch (e) {
        console.log('From LC error - ', e)
        return {}
    }
}

export function setFiltersToLocalStorage(key, val) {
    try {
        if (typeof val === 'object') {
            // TODO Anoop - Add the logic for recent 5 only
            let configsStr = localStorage.getItem(LSTORAGE_FILTER_DATA_KEY)
            let finalFilterConfigs = []
            if (configsStr) {
                finalFilterConfigs = JSON.parse(configsStr)
                if (Array.isArray(finalFilterConfigs)) {
                    let existingKey = false
                    let existingIdx = -1
                    for (let i = 0; i < finalFilterConfigs.length; i++) {
                        const config = finalFilterConfigs[i]
                        if (config[key]) {
                            existingIdx = i
                            existingKey = true
                            break
                        }
                    }
                    if (existingKey) {
                        // splice it
                        const replaceWith = {
                            [key]: val
                        }
                        finalFilterConfigs.splice(existingIdx, 1, replaceWith)
                    } else {
                        finalFilterConfigs.unshift({
                            [key]: val
                        })
                        // Add length logic here
                        if (finalFilterConfigs.length > LSTORAGE_COUNT_TO_SAVE)
                            finalFilterConfigs.splice(finalFilterConfigs.length - 1, 1)
                    }
                } else {
                    finalFilterConfigs = []
                    finalFilterConfigs.push({
                        [key]: val
                    })
                }
            } else {
                finalFilterConfigs.push({
                    [key]: val
                })
            }
            // console.log(finalFilterConfigs)
            // Check if the key exists.
            // Y - then overwrite
            // N - then add to the top
            //      Check if length of array > LSTORAGE_COUNT_TO_SAVE, Y - Delete last entry in array N - Don't Do anything
            // console.log('LSTORAGE_FILTER_DATA_KEY', finalFilterConfigs)
            localStorage.setItem(LSTORAGE_FILTER_DATA_KEY, JSON.stringify(finalFilterConfigs))
        } else logConsole('not object LC')
    } catch (e) {
        console.log('To LC error - ', e)
    }
}

const writeCookieUtm = (k, v, expiryInDays) => {
    if (expiryInDays > 0) {
        const now = new Date()
        const nextDate = now.setDate(now.getDate() + expiryInDays)
        const expiresDate = new Date(nextDate)
        document.cookie = `${k}=${v}; path=/; expires=` + expiresDate
    } else if (!expiryInDays || expiryInDays === '') {
        document.cookie = `${k}=${v};path="/";`
    }
    return null
}

export const readParam = (name, options = {}) => {
    const queryString = window.location.search.split('?')
    const urlHash = fromQueryString(queryString[1] ? queryString[1] : '')
    const params = urlHash ? urlHash : {}

    const cookieValue = getCookie(name || options.alias)
    const value = params[name] || params[options.alias]

    // if value present in param then return that, otherwise check in cookie
    if (value) {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        writeCookieUtm(name, value, options.expiry || 1)
        return value
    } else {
        if (cookieValue) {
            return cookieValue
        }
    }
}

export const FULL_NAMES_OF_DAYS = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
}

export const appPrefix = (() => {
    let langPrefix = ''
    let lang = 'en'
    // const LOCAL_LANG = process.env.LOCALE_LANG
    // const ENGLISH_LANG = 'en'

    const pathname = resolveKeysInObjectsArrays(['location', 'pathname'], window)

    const [, ln = lang] = (pathname || '').split('/')

    if (ln === 'ar') {
        langPrefix = '/ar'
        lang = ln
    }

    return ln === 'ar' ? `${langPrefix}` : ''
})()

//   const appRoutePrefix =  ln === 'ar' ? `${langPrefix}` : ''

export const checkFeatureEnabled = ({ featureConfig = {}, currentSearchQuery = {} }) => {
    const { locale: currentLocale } = getCurrencyByLocale()
    const { enabled = true, locales = [], intl = true, dom = true, roundTrip = null, oneWay = null } = featureConfig

    if (!enabled) {
        return false
    }

    if (intl === false && currentSearchQuery.isIntl) {
        return false
    }

    if (dom === false && currentSearchQuery.isIntl === false) {
        return false
    }

    if (locales.length) {
        if (locales.indexOf(currentLocale) === -1) {
            return false
        }
    }

    if (roundTrip === false && currentSearchQuery.returnDate) {
        return false
    }

    if (oneWay === false && !currentSearchQuery.returnDate) {
        return false
    }

    return true
}

export const arabicDateConverter = (formateDate, year = true, week = true) => {
    try {
        let date = formateDate.split(' ')
        if (!week) {
            return `${langTranslate(date[0])} ${langTranslate(date[1])}`
        }
        let convertedFormate = `${langTranslate(date[0].replace(',', ''))} ,${langTranslate(date[1])} ${langTranslate(
            date[2]
        )} ${langTranslate(date[3])}`
        if (!year) {
            convertedFormate = `${langTranslate(date[0].replace(',', ''))} ,${langTranslate(date[1])} ${langTranslate(
                date[2]
            )}`
        }
        return convertedFormate
    } catch (e) {
        return formateDate
    }
}

export const capitalizedFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}


export function capitalizeFirstCharInString(str) {
    if (typeof str !== 'string') {
        return str
    }

    // Split the input string into words using regular expression
    const words = str.split(/\s+/)

    // Capitalize the first character of each word
    const capitalizedWords = words?.map(word => {
        if (word?.length === 0) {
            return '' // Handle empty words
        } else if (word?.length === 1) {
            return word.toUpperCase() // Capitalize single-letter words
        } else {
            const firstChar = word[0].toUpperCase()
            const restOfWord = word?.slice(1).toLowerCase()
            return firstChar + restOfWord
        }
    })

    // Join the capitalized words back into a single string
    return capitalizedWords.join(' ')
}

export const convertCurrency = (price, currency, currencies) => {
    // let [currencies, setCurrencies] = useContext(CurrencyContext)
    // if (!isEmpty(currencyContext)) {
    //     currencies = currencyContext
    // }
    const conversionRate = !isEmpty(currency)
        ? getConversionRate(currency, currencies.currencyList)
        : currencies.conversionRate
    const { code: currencyCode, rate = '1' } = conversionRate || {}

    let sellingRate = 1
    const { currency: originalCurrency } = getCurrencyByLocale()
    if (!isEmpty(originalCurrency)) {
        const oriCurConvRate = getConversionRate(originalCurrency, currencies.currencyList) || {}
        sellingRate = oriCurConvRate.rate
    }
    let formattedCurrency = formatCurrency({ price, rate, currencyCode, sellingRate })
    return formattedCurrency
}

export const COUNTRY_MAP = {
    'http://localhost:3031': 'SA',
    'https://qa2.cleartrip.com': 'IN',
    'https://qa2new.cleartrip.com': 'IN',
    'https://airdev.cleartrip.com': 'IN',
    'https://qa2.cleartrip.ae': 'AE',
    'https://www.cleartrip.com': 'IN',
    'https://www.cleartrip.ae': 'AE',
    'https://om.cleartrip.com': 'OM',
    'https://qa.cleartrip.com': 'QA',
    'https://bh.cleartrip.com': 'BH',
    'https://kw.cleartrip.com': 'KW',
    'https://www.cleartrip.sa': 'SA',
    'https://me.cleartrip.com': 'ME',
    'https://www.cleartrip.eu': 'EU',
    'https://www.cleartrip.pl': 'PL',
    'https://www.cleartrip.co.za': 'ZA'
}

export function getScript(url, callback) {
    //create a script element and inject it into the page
    var script = document.createElement('script')
    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)

    //when the new script's load event fires, execute the callback
    script.onload = callback
}

export const ONWARD_DIR_ARROW = process.env.CONTENT_DIR === 'RTL' ? '←' : '→'

export const formSrpUrl = searchQuery => {
    let pathname = `${appPrefix}/flights/results`
    if (searchQuery.intl && searchQuery.intl === 'y') {
        pathname = `${appPrefix}/flights/international/results`
    }
    const stringify = toQueryString(searchQuery)
    let search = decodeURIComponent(stringify)
    return `${pathname}?${search}`
}
export const getExtension = () => {
    const currentDomain = resolveKeysInObjectsArrays(['location', 'hostname'], window)
    switch (currentDomain) {
        case 'www.cleartrip.com':
        case 'qa2.cleartrip.com':
        case 'localhost':
            return 'in'
        case 'www.cleartrip.ae':
        case 'qa2.cleartrip.ae':
            return 'ae'
        case 'bh.cleartrip.com':
            return 'bh'
        case 'om.cleartrip.com':
            return 'om'
        case 'kw.cleartrip.com':
            return 'kw'
        case 'qa.cleartrip.com':
            return 'qa'
        case 'www.cleartrip.sa':
        case 'qa2.cleartrip.sa':
            return 'sa'
        case 'me.cleartrip.com':
            return 'me'
        case 'www.cleartrip.me':
            return 'me'
        default:
            return 'notAvailable'
    }
}

export const isUserSignedIn = () => {
    let userObject = getUserAuthValues() || {}
    let usermiscVal = decodeURIComponent(getCookie('usermisc') || '').split('|')
    let signedIn = usermiscVal.includes('SIGNED_IN') && userObject.userId && userObject.userId.length > 0 ? true : false

    return signedIn
}

export const isCtUser = () => {
    const [email] = decodeURIComponent(getCookie('userid') || '').split('|')
    return email.indexOf('@cleartrip.com') > -1
}

export const replaceJSX = (str, find, replace) => {
    const isArray = Array.isArray(str)
    if (isArray) {
        let result = []
        str.forEach(subStr => {
            if (typeof subStr === 'string') {
                let parts = subStr.split(find)
                for (let i = 0; i < parts.length; i++) {
                    result.push(parts[i])
                    if (i < parts.length - 1) result.push(replace)
                }
            } else {
                result.push(subStr)
            }
        })
        return result
    }
    let parts = str.split(find)
    let result = []
    for (let i = 0; i < parts.length; i++) {
        result.push(parts[i])
        if (i < parts.length - 1) result.push(replace)
    }
    return result
}

export const constructHotelDetailsImageUrl = (image, transformations) => {
    let wideImage = ''
    if (image) {
        wideImage = image.replace(/_tn./i, '_w.') // eslint-disable-line
        // wideImage = image.replace(/_w./i, '.') // eslint-disable-line
    }

    let endPoint

    if (process.env.CT_ENV !== 'production') {
        endPoint = 'hotels_qa'
    } else {
        endPoint = 'hotels'
    }

    return `https://fastui.cltpstatic.com/image/upload/${transformations}/${endPoint}/places/hotels${wideImage}`
}
export const htmlSanitizer = (value) => {
    var re = /(<([^>]+)>)/gi;
    if (value.match(re)) {
        return ("")

    } else {
        return (value)
    }
}

export const GtmCodeInject =()=>{
    window.addEventListener('load', function() {(function(w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
        })(window, document, 'script', 'dataLayer', 'GTM-T9S432')
    })
}
export const GACode = ()=>{
    (function(w, d, s, i) {
        var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s)
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtag/js?id=' + i
        f.parentNode.insertBefore(j, f)
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', i, {send_page_view: false});
    })(window, document, 'script', 'G-M9WKWY8MDB')
}

export const loadRavenSDK = (url) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
  
      script.onload = () => {
        resolve();
      };
  
      script.onerror = () => {
        reject(new Error('Failed to load Raven SDK.'));
      };
  
      document.body.appendChild(script);
    });
  }

export function delete_cookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const checkHiFiveFlow = (pathname) => {
    const retVal =
        pathname &&
        pathname.length > 2 &&
        pathname[1] === 'ref' &&
        !isEmpty(pathname[2]) &&
        pathname[2] != 'null' &&
        pathname[2] != 'undefined'
    return retVal
}

export const dispatchNewRelic = ({ action_name, payload }) => {
    if (typeof newrelic == 'object') {
        newrelic.addPageAction(action_name, {
            channel: 'Desktop',
            ...payload
        })
    }
}
//returns value page_name in the login funnel instrumentation
export const checkPageName = () => {
    const currentURL = window.location.href
    const urlPattern = /\/(flights\/?)?$/;
    if (urlPattern.test(currentURL))
       return 'a_home';
    else if (currentURL.indexOf('/flights/results') !== -1) 
        return 'a_srp'
    else if (currentURL.indexOf('/flights/itinerary') !== -1) 
        return 'a_itinerary'
    else
        return 'NA';
}

// conditionalyy checking the fareName for CT Flex and CT Flexmax
export const checkFareName = (name) => {
    if(name === 'CT Flex') return 'ClearChoice Plus'
    else if(name === 'CT Flexmax') return 'ClearChoice Max' 
    else return name
}
/**
 * Get the current time in Indian Standard Time (IST).
 * @returns {Date} The current time in Indian Standard Time.
 */
export function getIndianTime() {
    // Get user's timezone offset in minutes
    const userTimeZoneOffset = new Date().getTimezoneOffset();

    // IST offset in minutes (UTC+5:30)
    const indianTimeZoneOffset = -330;

    // Calculate the time difference
    const timeDifferenceMinutes = indianTimeZoneOffset - userTimeZoneOffset;

    // Get the current time
    const currentTime = new Date();

    // Calculate Indian time by adding the time difference
    const indianTime = new Date(currentTime.getTime() + timeDifferenceMinutes * 60 * 1000);

    return indianTime;
}
export const abCookieForBBD = () => {
    return {...abResponseData}
}
