import langTranslate from 'locale'
export const NUM = 5
const skipArray = ['qa2.cleartrip.sa', 'qa2.cleartrip.ae', 'qa2.cleartrip.com']
// export let DOMAIN =
//     process.env.CT_ENV === 'development'
//         ? 'http://localhost:3031'
//         : skipArray.indexOf(window.location.hostname) >= 0 ? process.env.CT_PUBLIC_PLATFORM_URL : window.location.origin || 'https://platform.cleartrip.com/aircon-api'
export let DOMAIN =
    process.env.CT_ENV === 'development'
        ? 'http://localhost:3031'
        : window.location.origin || 'https://www.cleartrip.com'
export let HOSTNAME = window.location.hostname || 'www.cleartrip.com'
export let ANALYTICS_DOMAIN = process.env.CT_ANALYTICS_BASE_HOST_URL || 'https://analytics.cltpstatic.com'
export const NON_ARABIC_DOMAINS = ['IN', 'ZA', 'EU']
export const CT_ENV = process.env.CT_ENV
// One out of 3 params can be provided from ['cheap','normal','cfw']
export const DEFAULT_FARE_CARD_SELECTED = 'cheap'
export let ORIGIN_DOMAIN =
    process.env.CT_ENV === 'development'
        ? 'http://localhost:3031'
        : window.location.origin || 'https://www.cleartrip.com'
let male = langTranslate('Male')
let female = langTranslate('Female')
let maleObject = {
    adult: 'Mr',
    child: 'Mstr',
    infant: 'Mstr'
}
let femaleObject = {
    adult: 'Ms',
    child: 'Miss',
    infant: 'Miss'
}
export const GENDER_TITLE_MAPPING = {
    Others: {
        adult: 'Mr',
        child: 'Mstr',
        infant: 'Mstr'
    },
    Male: maleObject,
    Female: femaleObject,
    MALE: maleObject,
    FEMALE: femaleObject
}
if (langTranslate('Male') != 'Male') {
    GENDER_TITLE_MAPPING[male] = maleObject
    GENDER_TITLE_MAPPING[female] = femaleObject
}

export const TITLE_GENDER_MAPPING = {
    Mr: 'Male',
    Mstr: 'Male',
    Ms: 'Female',
    Miss: 'Female'
}

export const itineraryStatusForUserRetention = [
    'HOLD',
    'PAYMENT_RETRY',
    'PRICE_CHANGED',
    'PREPAYMENT_FAILED',
    'PRICE_CHANGED_SEATSELL1'
]
export const fareCardVasTypeList = [
    'AMEND_INSURANCE',
    'INTL_FLEXIFLY',
    'DOM_CANCELATION_INSURANCE',
    'INTL_CANCELATION_INSURANCE'
]

export const HI_FIVE_URL = '/accounts/hi-five'
export const hideSupercoinStatus = ['ACCOUNT_NOT_LINKED', 'WALLET_INACTIVE', 'UNKNOWN']

export const intlSortHaulFlightsList = [
    'HKT',
    'MLE',
    'DXB',
    'KTM',
    'BKK',
    'SIN',
    'AUH',
    'IST',
    'CMB',
    'MCT',
    'HAN',
    'RKT',
    'SHJ',
    'SGN',
    'DAC',
    'KUL',
    'LHR',
    'DOH',
    'KWI',
    'ATH',
    'BAH',
    'DMM',
    'LGW',
    'RUH',
    'AMS'
]

export const defaultAbValues = {
    gh35: 'a',
    gh30: 'a',
    itinerary_coupon: 'a',
    e_home: 'z',
    singlePax: 'a',
    ctUpgrade: 'c',
    vasExp7: 'b',
    vasExp6: 'b',
    mealImage: 'b',
    master_tuple: 'a',
    fareFamily: 'c',
    ff: 'a',
    gh36: 'b',
    gh26: 'a',
    highRps: 'c',
    otpless: 'd',
    tk_home: 'b',
    tk_itin: 'b',
    mfc: 'a',
    splrt: 'a',
    gh60: 'b',
    intlTravelIns: 'c',
    ptc_fare: 'b',
    gh62: 'a',
    inlineLogin_nudge: 'a',
    newHighRps:'b',
    New_Coupon_Experience:'b',
    ff_Intl: 'c',
    scV4: 'b',
}

export const emiDataReqCount = {
    oneWay: 15,
    roundTrip: 10
}

export const showEmiForAllSectors = true

export const showCouponFilters = true

// switch (process.env.CT_ENV) {
//     case 'staging':
//         DOMAIN = 'https://qa2.cleartrip.com'
//         break
//     case 'production':
//         DOMAIN = 'https://www.cleartrip.com'
//         break
//     case 'development':
//         DOMAIN = 'http://localhost:3031'
//         break
//     default:
//         DOMAIN = 'https://www.cleartrip.com'
// }
