
import { getCookie, resolveKeysInObjectsArrays, setCookie } from "./browserHelper";


export const setFailedExperimentInSessionStorage = experimentName => {
    let failedExperimentArray = getFailedExperimentsFromSessionStorage()
    failedExperimentArray.push(experimentName)
    sessionStorage.setItem('failedExperimentArray', JSON.stringify(failedExperimentArray))
}

export const getFailedExperimentsFromSessionStorage = () => {
    return JSON.parse(sessionStorage.getItem('failedExperimentArray')) || []
}

export const checkIfExperimentFailedOnPrevPage = experimentName => {
    let failedExperimentArray = getFailedExperimentsFromSessionStorage()
    if (failedExperimentArray.includes(experimentName)) {
        return true
    }
    return false
}
export const setStatsigIDInNativeCookie = () => {
    // we are passing isSignIn as "true" so that in native we can copy all the cookies from webview to native storage
    // if we pass the actual loginStatus and if it is "false" then the native code will delete all the cookies stored in native
    if (resolveKeysInObjectsArrays(['androidData', 'app-agent'], window) && path(['androidData', 'js-version'], window)) {
        window.MobileApp.onPWALoginStatus(
            JSON.stringify({
                isSignIn: true
            })
        )
    }

    //we need to add this polling because the ios bridge methods are not readily available in the webview
    let attemptToFetchBridgeMethod = 1
    const intervalId = setInterval(() => {
        if (resolveKeysInObjectsArrays(['iosData', 'app-agent'], window) && path(['iosData', 'js-version'], window)) {
            clearInterval(intervalId)
            window.webkit.messageHandlers['PWA_IS_SIGNIN'].postMessage({
                isSignIn: true
            })
        } else if (attemptToFetchBridgeMethod >= 10) {
            clearInterval(intervalId)
        }
        attemptToFetchBridgeMethod++
    }, 500)
}

export const isStatsigIDInLocalStorageOrCookies = statsigCustomID => {
    try {
            const localStorageValue = window?.localStorage?.getItem('STATSIG_LOCAL_STORAGE_STABLE_ID')
            const cookieValue = getCookie('statsig-stableid')

            if (cookieValue) return cookieValue

            if (localStorageValue) {
                setCookie('statsig-stableid', localStorageValue, 365)
                return localStorageValue
            }

            if (!cookieValue || !localStorageValue) {
                setCookie('statsig-stableid', statsigCustomID, 365)
                // setStatsigIDInNativeCookie only when cookie is not already present
                // i.e. old logged-in users opening updated app with Statsig integration or any old users using old app without Statsig integration
                // this is done to prevent the generation of different StableIDs every time app is launched
                setStatsigIDInNativeCookie()
                // returning the statsigCustomID will initialize statsig SDK using the customID, and it will add the same in local storage as well
                return statsigCustomID
            }
    } catch (error) {
        console.error('Error accessing storage:', error)
    }
    return statsigCustomID
}

