import { getCookie, isEmpty } from './browserHelper'
import { DOMAIN_COUNTRY_MAP } from 'constants/flightResults'
import { getBbdJson, getUserProfileDetails } from 'api/flights/results'

import { DOMAIN } from 'constants/base'
import { responseMapper, sendProfileData } from 'analytics/clevartapProfile'
export const normalizeSize = size => {
    if (typeof size === 'number') return `${size}px`
    return `${size}`
}

export const replacePlaceHolder = (text, data) => {
    if (!isEmpty(text) && !isEmpty(data)) {
        const placeholders = text.match(/[^{\}]+(?=})/g)
        text = text.replace(/[{}]/g, '')
        if (!isEmpty(placeholders)) {
            placeholders.forEach((item, index) => {
                text = text.replace(item, data[item])
            })
        }
    }
    return text
}
  
export const newCouponExp = (abtest ,isMetaPartner=false)=> {
    if (isMetaPartner === true) {
        return true
    }
    if (abtest?.New_Coupon_Experience === 'a' || abtest?.New_Coupon_Experience === 'c') {
        return true
    } else {
        return false
    }
  }

const padLeft = (str, len, pad) => {
    if (typeof len == 'undefined') {
        var len = 0
    }
    if (typeof pad == 'undefined') {
        var pad = ' '
    }

    if (len + 1 >= str.length) {
        str = Array(len + 1 - str.length).join(pad) + str
    }

    return str
}
export const getPopularDestinationUrl = page_url => {
    if (isEmpty(page_url)) return null
    const today = new Date()
    const d1 = new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000)
    const d2 = new Date(today.getTime() + 4 * 24 * 60 * 60 * 1000)
    const dd = padLeft(String(d1.getDate()), 2, '0')
    const nextDD = padLeft(String(d2.getDate()), 2, '0')
    const m1 = padLeft(String(d1.getMonth() + 1), 2, '0') //January is 0!
    const m2 = padLeft(String(d2.getMonth() + 1), 2, '0')
    const y1 = d1.getFullYear()
    const y2 = d2.getFullYear()
    return page_url.replace('{@checkin}', dd + '/' + m1 + '/' + y1).replace('{@checkout}', nextDD + '/' + m2 + '/' + y2)
}

export const modifyConfig = (config = {}, expValue) => {
    if (isEmpty(config)) {
        return {}
    }
    const { desktopHomePageBannerNew: { a = {}, b = {}, c = {}, d = {} } = {} } = config
    let expObj = {
        a: {
            desktopHomePageBannerNew: false,
            url: a.url,
            redirectUrl: a.redirectUrl
        },
        b: {
            desktopHomePageBannerNew: true,

            url: b.url,
            redirectUrl: b.redirectUrl
        },
        c: {
            desktopHomePageBannerNew: true,

            url: c.url,
            redirectUrl: c.redirectUrl
        },
        d: {
            desktopHomePageBannerNew: true,

            url: d.url,
            redirectUrl: d.redirectUrl
        }
    }
    let experiment = expObj[expValue] || expObj['a']
    return {
        ...config,
        desktopHomePageBannerNew: {
            ...config.desktopHomePageBannerNew,
            enabled: experiment.desktopHomePageBannerNew,
            properties: {
                url: experiment.url,
                redirectUrl: experiment.redirectUrl
            }
        }
    }
}

export const  getFeatureAccessFromABTest = (abTest, key) => {
    if((abTest || {})[key] === 'a') {
      return false;
   }
    //Run new flow in all the other cases.
    return true
  }
export const isFeatureEnabledNew = async () => {
    const data= await getBbdJson()
    let currentTime = new Date()

    let currentOffset = currentTime.getTimezoneOffset()

    let ISTOffset = 330 // IST offset UTC +5:30

    let ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000)

    if (DOMAIN_COUNTRY_MAP[DOMAIN] === 'IN') {
        let obj = {}
        data.data.forEach((ele)=>{
            if(new Date(ele.time.from)<= ISTTime && new Date(ele.time.to) >= ISTTime){
                obj = { ...ele }
            }
        })
        return obj
    } else {
        return { travelsafeEnabled: true }
    }
}
export const isFeatureEnabled = () => {
    const data = window.offerJson || []
    let currentTime = new Date()

    let currentOffset = currentTime.getTimezoneOffset()

    let ISTOffset = 330 // IST offset UTC +5:30

    let ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000)

    // let obj = {
    //     a: {
    //         bbdBannerEnabled: true,
    //         popularDestinationEnabled: true
    //     },
    //     b: {
    //         bbdBannerEnabled: true,
    //         popularDestinationEnabled: false
    //     },
    //     c: {
    //         bbdBannerEnabled: false,
    //         popularDestinationEnabled: true
    //     },
    //     d: {
    //         bbdBannerEnabled: true,
    //         popularDestinationEnabled: true
    //     }
    // }
    const ctAb = isEmpty(getCookie('ct-ab')) ? {} : JSON.parse(decodeURIComponent(getCookie('ct-ab')))
    let expConfig = ctAb['e_home'] || 'a'

    if (DOMAIN_COUNTRY_MAP[DOMAIN] === 'IN') {
        let obj = {}
        for (let index = 0; index < data.length; index++) {
            let config = data[index]
            if (new Date(config.time.from) <= ISTTime && new Date(config.time.to) >= ISTTime) {
                // obj = modifyConfig({ ...config }, expConfig)
                obj = { ...config }
                break
            }
        }
        return obj
    } else {
        return { travelsafeEnabled: true }
    }
}

export const isTravelSafeBannerEnabled = (travelSafeCountryList = [], searchType = {}, to, from) => {
    if (DOMAIN_COUNTRY_MAP[DOMAIN] === 'IN') {
        return (travelSafeCountryList.includes(searchType[to]) ||
            travelSafeCountryList.includes(searchType[from]))
    } else {
        return false
    }
}

export const shouldShowCodBanner = (showCodBanner , to , from) => {
return !isEmpty(showCodBanner) && (showCodBanner.includes(to) || showCodBanner.includes(from))
}

export const truncateString = (str, num) => {
    if ((str)) {
      if (str.length > num) {
        return str.slice(0, num) + '...'
      } else {
        return str
      }
    }
  }

export const getGoogleOptAbValue = (qaExpId, prodExpId = '', valueArr = ['1']) => {
    let domain = window?.location?.hostname
    if (window?.google_optimize) {
      let value = window.google_optimize.get(domain === 'qa2new.cleartrip.com' ? qaExpId : prodExpId);
      if (valueArr.includes(value)) {
        return true
      }
    }
    return false
}

export const activateGoogleOptimize = (dataLayer) => {
    window?.dataLayer?.push({'event': 'optimize.activate'});
}


export const profilePush = async () => {
    try {
        const getResponse = await getUserProfileDetails()
        const { data, status } = getResponse || {}
        if (status == 200) {
            let finalPayload = responseMapper(data)
            sendProfileData(finalPayload)
        }
    } catch (e) {
        console.log('User profile details api error', e)
    }
}