export function updateRecentSearch(k, v) {
    try {
        if (typeof window.localStorage != 'undefined') {
            const isRtl = process.env.CONTENT_DIR === 'RTL'
            let storageName = isRtl ? 'recentAirportAr' : 'recentAirport'
            let recentSearch = JSON.parse(localStorage.getItem(storageName))
            recentSearch = recentSearch || []
            let eleFound = false
            if (recentSearch.length) {
                for (let i = 0; i < recentSearch.length; i++) {
                    if (recentSearch[i].k == k) {
                        eleFound = true
                        break
                    }
                }
            }
            if (!eleFound) {
                recentSearch.unshift({ k, v })
            }
            if (recentSearch.length >= 10) {
                recentSearch.length = 10
            }
            localStorage.setItem(storageName, JSON.stringify(recentSearch))
        }
    } catch (e) {
        console.log('updateRecentSearch LC Error', e)
    }
}

export function updateHomeSearch(obj) {
    try {
        if (typeof window.localStorage != 'undefined') {
            const isRtl = process.env.CONTENT_DIR === 'RTL'
            let storageName = 'fsHistory'
            let dataForm = { en: [], ar: [] }
            let recentSearch = JSON.parse(localStorage.getItem(storageName)) || dataForm
            let lang = isRtl ? 'ar' : 'en'
            //recentSearch = recentSearch[lang]
            let eleFound = false
            if (recentSearch[lang].length) {
                for (let i = 0; i < recentSearch[lang].length; i++) {
                    if (
                        recentSearch[lang][i].from == obj.from &&
                        recentSearch[lang][i].to == obj.to &&
                        recentSearch[lang][i].adults == obj.adults &&
                        recentSearch[lang][i].class == obj.class &&
                        recentSearch[lang][i].depart_date == obj.depart_date &&
                        recentSearch[lang][i].return_date == obj.return_date &&
                        recentSearch[lang][i].airline == obj.airline
                    ) {
                        eleFound = true
                        break
                    }
                }
            }
            if (!eleFound) {
                recentSearch[lang].unshift({ ...obj })
            }
            if (recentSearch[lang].length >= 3) {
                recentSearch[lang].length = 3
            }

            localStorage.setItem(storageName, JSON.stringify(recentSearch))
        }
    } catch (e) {
        console.log('updateRecentSearch LC Error', e)
    }
}

export function getflightsHistory() {
    try {
        if (typeof window.localStorage != 'undefined') {
            const isRtl = process.env.CONTENT_DIR === 'RTL'
            let storageName = 'fsHistory'
            let dataForm = { en: [], ar: [] }
            let lang = isRtl ? 'ar' : 'en'
            let recentSearch = JSON.parse(localStorage.getItem(storageName)) || dataForm
            return recentSearch[lang]
        } else {
            return []
        }
    } catch (e) {
        console.log('getRecentSearch LC Error', e)
    }
}

export function getRecentSearch(recentAirport) {
    try {
        if (typeof window.localStorage != 'undefined') {
            const isRtl = process.env.CONTENT_DIR === 'RTL'
            let storageName = isRtl ? 'recentAirportAr' : 'recentAirport'
            let recentSearch = JSON.parse(localStorage.getItem(storageName))
            return recentSearch || []
        } else {
            return []
        }
    } catch (e) {
        console.log('getRecentSearch LC Error', e)
    }
}