import React, { lazy, Suspense, useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
// import OutsideClickHandler from '../OutsideClickHandler'
import Overlay from '../Overlay'
import animationData from './Confetti.json'
const Lottie = lazy(() => import(/* webpackChunkName: "lottie" */ 'react-lottie'))
const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}
const Modal = ({
    className,
    children,
    isShowing,
    parentClassname,
    toggle,
    overlayStyle,
    overlayClassName,
    isCross = false,
    showAnimation,
    setShowAnimation,
    onOverlayClick = () => {},
    ...otherProps
}) => {
    const modalRef = useRef(null)
    const [dimentions, setDimensions] = useState({
        height: 200,
        width: 400
    })

    useEffect(() => {
        if (showAnimation) {
            setDimensions({
                height: modalRef.current?.clientHeight + 150,
                width: modalRef.current?.clientWidth + 48
            })
        }
    }, [isShowing])
    return (
        isShowing &&
        ReactDOM.createPortal(
            <Overlay style={overlayStyle} className={overlayClassName} onOverlayClick={onOverlayClick}>
                {showAnimation && (
                    <div
                        className="flex flex-column flex-center flex-middle p-fixed t-0 b-0 l-0 r-0 br-3 pe-none"
                        style={{
                            height: 'fit-content',
                            margin: 'auto 24px',
                            borderRadius: '12px',
                            zIndex: 351
                        }}
                    >
                        <Suspense fallback={<></>}>
                            <Lottie
                                options={defaultOptions}
                                isClickToPauseDisabled={true}
                                eventListeners={[
                                    {
                                        eventName: 'complete',
                                        callback: () => setShowAnimation(false)
                                    }
                                ]}
                                speed={1}
                                height={dimentions.height}
                                width={dimentions.width}
                                className="p-fixed"
                            />
                        </Suspense>
                    </div>
                )}

                <div className={parentClassname ? parentClassname : 'p-relative'} ref={modalRef}>
                    {children}
                    {isCross && (
                        <div
                            onClick={toggle}
                            style={{ top: '-44px', width: '32px' }}
                            className="c-pointer bg-white br-100 flex flex-center flex-middle h-8 p-absolute r-0"
                        >
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="32" height="32" rx="16" fill="white" />
                                <path
                                    d="M22 10L16 16M16 16L10 22M16 16L10 10M16 16L22 22"
                                    stroke="#1A1A1A"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    )}
                </div>
            </Overlay>,
            document.body
        )
    )
}

export default Modal
