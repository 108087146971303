import langTranslate from 'locale'
import { API_STATES } from 'constants/stateMaps'

export const DEPARTURE_TIME_MAPPING = {
           '0000': {
               label: langTranslate('Early morning'),
               subLabel: langTranslate('Midnight - 8 am'),
               intlLabel: '12am_8am',
               value: '0000',
               time: {
                   start: 0,
                   end: 800
               }
           },
           '0800': {
               label: langTranslate('Morning'),
               subLabel: langTranslate('8 am - Noon'),
               intlLabel: '8am_12pm',
               value: '0800',
               time: {
                   start: 800,
                   end: 1200
               }
           },
           '1200': {
               // label: langTranslate('Mid-day'),
               label: langTranslate('Afternoon'),
               subLabel: langTranslate('Noon - 4 pm'),
               intlLabel: '12pm_4pm',
               value: '1200',
               time: {
                   start: 1200,
                   end: 1600
               }
           },
           '1600': {
               label: langTranslate('Evening'),
               subLabel: langTranslate('4 pm - 8 pm'),
               intlLabel: '4pm_8pm',
               value: '1600',
               time: {
                   start: 1600,
                   end: 2000
               }
           },
           '2000': {
               label: langTranslate('Night'),
               subLabel: langTranslate('8 pm - Midnight'),
               intlLabel: '8pm_12am',
               value: '2000',
               time: {
                   start: 2000,
                   end: 2400
               }
           }
       }

export const CFW_STATUS_FLAGS = ['TRIAL', 'TRIAL_NCB', 'VERIFIED', 'UNVERIFIED']

export const DATE_TAB_MATRICES = {
    fullScreen: {
        totalDays: 6,
        nextDays: 3,
        prevDays: 3
    },
    splitScreen: {
        totalDays: 2,
        nextDays: 1,
        prevDays: 1
    }
}

export const LSTORAGE_FILTER_DATA_KEY = 'FILTER_DATA'
export const LSTORAGE_COUNT_TO_SAVE = 5

export const DOMAIN_COUNTRY_MAP = {
    'http://localhost:3031': 'IN',
    'http://localhost:3000': 'IN',
    'https://qa2.cleartrip.com': 'IN',
    'https://qa2new.cleartrip.com': 'IN',
    'https://airdev.cleartrip.com': 'IN',
    'https://qa2.cleartrip.ae': 'AE',
    'https://qa2new.cleartrip.ae': 'AE',
    'https://www.cleartrip.com': 'IN',
    'https://www.cleartrip.ae': 'AE',
    'https://om.cleartrip.com': 'OM',
    'https://qa.cleartrip.com': 'QA',
    'https://bh.cleartrip.com': 'BH',
    'https://kw.cleartrip.com': 'KW',
    'https://www.cleartrip.sa': 'SA',
    'https://qa2.cleartrip.sa': 'SA',
    'https://qa2new.cleartrip.sa': 'SA',
    'https://me.cleartrip.com': 'ME',
    'https://qa2me.cleartrip.com': 'ME',
    'https://www.cleartrip.eu': 'EU',
    'https://www.cleartrip.pl': 'PL',
    'https://www.cleartrip.co.za': 'ZA',
    'https://qa2kw.cleartrip.com': 'KW',
    'https://qa2om.cleartrip.com': 'OM',
    'https://qa2bh.cleartrip.com': 'BH',
    'https://qa2qa.cleartrip.com': 'QA',
    'https://www.cleartrip.me': 'ME'
}

export const API_STATES_FOUR = {
    ...API_STATES,
    INIT: 'INIT'
}

export const GSTIN_REGEX = /((0[1-9])|([1-2][0-9])|(3[0-7]))\s?\-?\s?[A-Za-z]{5}\d{4}[A-Za-z]\s?\-?\s?\d[A-Za-z][A-Za-z0-9]/i

export const PRICEVARIANTS = {
    INCREASED: 'INCREASED',
    DECREASED: 'DECREASED',
    NOT_AVAILABLE: 'NOT_AVAILABLE',
    NEAR_BY_AIRPORT: 'NEAR_BY_AIRPORT'
}

export const flightErrorStatus = {
    PRICE_CHANGED_SEATSELL1: 'PRICE_CHANGED_SEATSELL1',
    PRICE_CHANGED: 'PRICE_CHANGED',
    FLIGHT_NOT_AVAILABLE: 'FLIGHT_NOT_AVAILABLE',
    AIRPORT_CHANGE: 'AIRPORT_CHANGE',
    HOLD: 'HOLD',
    PARTIAL_SUCCESS: 'PARTIAL_SUCCESS'
}
